<template>
  <div class="container">
    <div class="container-content">
      <div class="head-item">
        <!-- 部门 -->
        <div class="item-content">
          <label>部门：</label>
          <el-select v-model="params.teamId" placeholder="请选择部门">
            <el-option v-for="item in teamsList"
              :key="item.id"
              :label="item.label" 
              :value="item.id">
            </el-option>
          </el-select>
        </div>

        <!-- 来源 -->
        <div class="item-content">
          <label>来源：</label>
          <el-select v-model="params.sourceType" placeholder="请选择资源类型">
            <el-option v-for="item in sourceList" :key="item.val"
              :label="item.name"
              :value="item.val">
            </el-option>
          </el-select>
        </div>

        <!-- 时间 -->
        <div class="item-content">
          <label>时间：</label>
          <el-date-picker v-model="params.times" type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :clearable="false">
          </el-date-picker>
        </div>

        <!-- 查询 -->
        <el-button @click="getUserEvtList" type="primary" icon="el-icon-search">查 询</el-button>
      </div>

      <div class="head-item">
        <!-- 姓名 -->
        <div class="item-content">
          <label>客户：</label>
          <el-input style="display: inline-block; width: 160px"
            v-model="customerName" placeholder="请输入客户姓名"></el-input>
        </div>

        <!-- 销售 -->
        <div class="item-content">
          <label>销售：</label>
          <el-button @click="selectQueryStaff" plain style="width: 160px">选择销售</el-button>
        </div>
        
      </div>

      <div v-if="queryStaffList.length > 0" class="head-staff" >
        <label>已选择的销售：</label>
        <label @click="delQueryStaff('all')" class="head-staff__del">清空</label>
        <el-tag size="mini" closable class="head-staff__tag"
          @close="delQueryStaff(item.id)"
          v-for="item in queryStaffList" :key="item.id">{{item.label}}</el-tag>
      </div>

      <!-- 表格 -->
      <el-table class="table-style"
        ref="multipleTable"
        tooltip-effect="dark"
        header-row-class-name="table-header-style"
        :data="tableData.filter(e => !customerName || (e.nickname && e.nickname.indexOf(customerName) != -1))"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
        <!-- 头像 -->
        <el-table-column label="头像" prop="name" align="center" width="60px">
          <template slot-scope="scope">
            <img class="head-img" :src="scope.row.avatar || $configs.headImg">
          </template>
        </el-table-column>
        <!-- 姓名 -->
        <el-table-column label="姓名" prop="nickname" align="center" width="120px"></el-table-column>
        <!-- 电话 -->
        <el-table-column label="电话" align="center">
          <template slot-scope="scope">
            {{scope.row.tel || '--'}}
          </template>
        </el-table-column>
        <!-- 时间 -->
        <el-table-column label="最后访问时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.last_visit_time | dateFormat}}</span>
          </template>
        </el-table-column>
        <!-- 来源 -->
        <el-table-column label="来源" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.source | userSource}}</span>
          </template>
        </el-table-column>
        <!-- 所属销售 -->
        <el-table-column label="所属销售" prop="xname" align="center"></el-table-column>
        <!-- 转移 -->
        <el-table-column label="操作" prop="rt" align="left" width="120px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-sort" plain @click="selectStaff(scope.row)">转移</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import staffDialog from '@/websites/cms/js/dialog'
export default {
  data(){
    return {
      tableData: [],
      query: {
        size: 1000,
        after: '',
        status: 1
      },
      body: null,
      customerName: '',
      sourceList: [                                  // 资源列表
        {name: '全部', val: ''},
        {name: '名片', val: 'card'},
        {name: '文章', val: 'article'},
        {name: '官网', val: 'site'},
        {name: '视频', val: 'video'}
      ],
      params: {
        times: [
          this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          this.$dayjs().format('YYYY-MM-DD')
        ],
        sourceType: '',
        teamId: ''
      },
      teamsList: [],                                 // 部门列表
      queryStaffList: [],                            // 查询条件的销售列表
    }
  },
  created(){
    this.getCompany();
  },
  methods: {
    // 获取公司信息
    async getCompany(){
      let res = await this.$ZGManager.getMyCompany();
      if(res.status == 200){
        this.teamsList.push({
          id: res.data.id,
          label: res.data.shortName || '全部人员',
        });
        this.params.teamId = this.params.companyId = res.data.id;
        this.getMyTeams();
      }
    },

    // 获取我的团队列表
    async getMyTeams(){
      let res = await this.$ZGManager.getMyTeams();
      if(res.status == 200){
        res.data.map((e) => {
          this.teamsList.push({
            id: e.id,
            label: e.name,
          });
        });
        this.getUserEvtList();
      }
    },

    async getUserEvtList(){
      // 组装请求
      let params = {ranges: [{
        from: this.$dayjs(this.params.times[0]).format('YYYY-MM-DD'), 
        to: this.$dayjs(this.params.times[1]).add(1, 'day').format('YYYY-MM-DD')
      }]};
      if(this.params.sourceType) params.rt = this.params.sourceType;
      if(this.params.companyId == this.params.teamId){
        params.cid = this.params.companyId;
      }else{
        params.tid = this.params.teamId;
      };
      if(this.queryStaffList.length > 0){
        params.xid = '';
        this.queryStaffList.map((item, index) => {
          if(index == this.queryStaffList.length - 1){
            params.xid += item.id;
          }else{
            params.xid += `${item.id},`;
          }
        });
      }

      // 查询数据
      let res = await this.$ZGManager.statCustomerList(params, this.query);
      if(res.status != 200) return;
      this.getStaffName(res.data.list);
    },

    // 查询销售名字
    async getStaffName(data){
      let xid = "";
      data.map(e => xid += `${e.xid},`);
      if(xid) xid = xid.slice(0, xid.length - 1);
      let res = await this.$ZGManager.getStaffName(xid);
      if(res.status == 200 && res.data.length > 0){
        data.map((e) => {
          let index = res.data.findIndex(item => item.id == e.xid);
          if(index != -1) e.xname = res.data[index].name;
        });
      }
      this.tableData = data;
    },

    // 选择员工
    selectStaff(user){
      staffDialog.show({
        type: 'selectStaff',
        staffList: [{id: user.xid}],
        selected: (item) => {
          this.changeCustomerStaff(user.utk, item[0].id);
        }
      });
    },

    // 将更改记录同步至服务器
    async changeCustomerStaff(utk, xid){
      let res = await this.$ZGManager.changeCustomerStaff(utk, xid);
      if(res.status == 200){
        setTimeout(() => {
          this.getUserEvtList();
        }, 300);
      }
    },

    // 选择查询条件的员工
    selectQueryStaff(){
      staffDialog.show({
        type: 'selectStaff',
        staffList: this.queryStaffList,
        selected: (item) => {
          this.queryStaffList = item;
        }
      });
    },

    // 删除查询条件的员工
    delQueryStaff(id){
      if(id == 'all') return this.queryStaffList = [];
      let index = this.queryStaffList.findIndex(e => e.id == id);
      if(index != -1){
        this.queryStaffList.splice(index, 1);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container-content{
  padding: 15px;
  background-color: #fff;
}
.head-item{
  margin-bottom: 10px;
  .item-content{
    display: inline-block;
    margin-right: 30px;
    label{
      font-size: 14px;
      color: #666;
      margin-right: 5px;
    }
    ::v-deep .el-select{
      width: 160px;
    }
    ::v-deep .el-date-editor{
      width: 406px;
    }
  }
}
.head-staff{
  color: #999;
  .head-staff__tag{
    margin-right: 15px;
  }
  .head-staff__del{
    color: #ff0000;
    padding-right: 15px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.table-style{
  width: 100%; 
  margin-top: 10px;
  .head-img{
    width: 40px;
    height: 40px;
  }
}
</style>